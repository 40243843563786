import React from 'react';
import ColoredText from '../../components-lib/ColoredText/ColoredText';
import Article from '../../components/Article/Article';


const Discrimination = () => (
  <Article
    title={<ColoredText>Diskriminácia</ColoredText>}
    imageAlt="Ilustrácia ženy, ktorá pomáha svojej dcére vyrovnať sa s diskrimináciou"
    imageLink="/svg/illustrations/discrimination.svg"
  >
    <p>
      <b>Diskriminácia</b> je také konanie, keď sa v tej istej situácii zaobchádza s jedným človekom alebo skupinou ľudí inak, než by sa zaobchádzalo s iným človekom alebo skupinou ľudí. Vo všeobecnosti môže ísť o poškodzovanie, obmedzovanie alebo znevýhodňovanie jednotlivca alebo skupiny osôb na základe jedného alebo viacerých diskriminačných dôvodov.
    </p>
    <p>
      <b>Právo na rovnaké zaobchádzanie</b> je základným ľudským právom, ktorého bližším premietnutím je zásada rovnakého zaobchádzania, ktorá neznamená len rovnaké zaobchádzanie s ľuďmi v rovnakých situáciách, ale uplatnenie tohto práva súčasne vyjadruje požiadavku, aby sa s nositeľmi ľudských práv zaobchádzalo v nerovnakých situáciách nerovnako. Z praxe Európskeho súdu pre ľudské práva vyplýva, že k diskriminácii môže dôjsť aj vtedy, ak sa bez objektívneho a rozumného ospravedlnenia nezaobchádza rozdielne s osobami, ktorých situácia je rozdielna. Avšak nie každé odlišné zaobchádzanie musí byť diskrimináciou (napr. tehotné ženy nesmú byť zamestnávané prácami, ktoré sú pre ne fyzicky neprimerané alebo škodia ich organizmu. V tomto prípade sa sleduje legitímny cieľ, a tým je ochrana nenarodeného života a života matky), a preto je potrebné mať na pamäti, že rozdielne zaobchádzanie je diskriminačné len vtedy, ak pre takéto správanie, resp. rozlišovanie nie je objektívny a primeraný dôvod.
    </p>
    <p>
      Diskriminácia je v moderných demokratických spoločnostiach považovaná za neprípustnú a minimálne jej vyššie vymedzené formy sú v súčasnosti zakazované zákonmi a medzinárodnými zmluvami.
    </p>

    <p>
      Spoločným a základným právnym základom pre dodržiavanie zásady rovnakého zaobchádzania je zákon č. 365/2004 Z.z. (antidiskriminačný zákon) (ďalej len ako „ADZ“), ktorý zakotvuje základné pojmy súvisiace s problémom diskriminácie pre celý právny poriadok Slovenskej republiky.
    </p>
  </Article>
);

export default Discrimination;
